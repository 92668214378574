<style scoped>
div {
  box-sizing: border-box;
}

.carousel-bg >>> .el-carousel {
  width: 100%;
  height: 100%;
}

.carousel-bg >>> .el-carousel__container {
  width: 100%;
  height: 100%;
  z-index: 111;
}

.carousel-bg >>> .el-carousel__indicators {
  display: none;
}
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
.carousel-bg {
	width: 100%;
		height: 115vh;
		min-width: 1555px;
		background: #4EA7F9;
		border-radius: 0 0 10% 10%;
		border-bottom-left-radius: 55% 14%;
		border-bottom-right-radius: 55% 14%;
		position: relative;
		z-index: 999;
		overflow: hidden;
}

.carousel-bg img {
  width: 100%;
  height: 100%;
}

.toBotton-icon {
  position: absolute;
  width: 69px;
  height: 69px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.title-1 {
  width: 1555px;
  margin: 0 auto;
  flex-direction: column;
  transition: all 0.3s;
}

.title-1 > p:nth-child(1) {
  font-size: 32px;
  color: #252b3a;
  margin: 60px auto 34px;
}

.title-1 > p:nth-child(2) {
  font-size: 15px;
  color: #676b76;
}

.box-1 {
  width: 1555px;
  margin: 0 auto;
  padding: 100px 190px;
  box-sizing: border-box;
}

.box-1-item {
  flex-direction: column;
  font-size: 16px;
  color: #252b3a;
  position: relative;
}
.box-1-item::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 50%;
  width: 0;
  height: 6px;
  transform: translateX(-50%);
  background: #67b4f9;
  border-radius: 5px;
  transition: all 0.6s;
}

.box-1-item-icon {
  width: 108px;
  height: 108px;
  margin-bottom: 25px;
  transition: all 0.8s;
}

.box-1-item-icon-active {
  border-radius: 50%;
  box-shadow: 0 0 15px #f5f5f5;
}

.box-1-item-icon > img {
  height: 46px;
  width: 56px;
}

.box-1-active {
  color: #67b4f9;
}
.box-1-active::after {
  width: 30px;
}

.box-2 {
  width: 1555px;
  margin: 0 auto 190px;
}

.box-2-item {
  width: 400px;
  height: 230px;
  padding: 26px 36px;
  border: 1px solid #e6e9f0;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 10px #f8f8f9;
}

.box-2-item:hover {
  background-image: url(../assets/images/index-nav3-bg_03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff !important;
  color: #fff;
  transform: translateY(-30px);
  transition: transform 0.5s;
}

.box-2-item:hover .box-2-p1 {
  color: #fff;
}

.box-2-item:hover .box-2-p2 {
  color: #fff;
}

.box-2-p1 {
  font-size: 15px;
  color: #252b3a;
  margin-bottom: 30px;
  font-weight: bold;
}

.box-2-p2 {
  color: #666a75;
  font-size: 14px;
}

.box-3 {
  width: 100%;
  height: 1184px;
  min-width: 1555px;
}

.box-3-left {
  width: 39.5%;
  height: 100%;
  background: #75b9f6;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.list-boxs {
  width: 180px;
  height: 120px;
  position: absolute;
  top: 105px;
  left: 30px;
  z-index: 1;
  flex-wrap: wrap;
}
.list-boxs-unp {
  width: calc(100% / 8);
  margin-bottom: 20px;
}
.unp-inner {
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
}
.box-unp1 {
  width: 345px;
  height: 320px;
  border-radius: 50%;
  background: #6ba9f2;
  position: absolute;
  top: 220px;
  left: -174px;
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 5s; /*动画所花费的时间*/
}
.box-unp2 {
  width: 345px;
  height: 320px;
  border-radius: 50%;
  background: #71b1f8;
  position: absolute;
  top: 465px;
  left: -174px;
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 5s; /*动画所花费的时间*/
}
.box-unp3 {
  width: 345px;
  height: 320px;
  border-radius: 50%;
  background: #6eaef8;
  position: absolute;
  top: 40px;
  right: -174px;
  -webkit-animation-name: scaleDraw; /*关键帧名称*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
  -webkit-animation-duration: 5s; /*动画所花费的时间*/
}
.box-3-inner {
  position: absolute;
  top: 190px;
  left: 250px;
}

.box3-inner1 {
  width: 48px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
}

.box3-inner2 {
  margin: 90px 0;
}

.box3-inner2 > p {
  color: #fff;
  font-size: 32px;
  letter-spacing: 10px;
}

.box-3-right {
  width: 60.5%;
  height: 100%;
  background: #f5f8fc;
  padding: 120px 100px 0 225px;
  box-sizing: border-box;
  overflow: hidden;
}

.box-3-right > div:first-child {
  height: 6px;
  width: 48px;
  border-radius: 3px;
  background: #75b9f6;
  margin-bottom: 90px;
  box-shadow: 0 2px 5px #75b9f6;
}

.box-3-p1 {
  font-size: 34px;
  color: #252b3a;
  margin-bottom: 35px;
}

.box-3-p2 {
  color: #666a75;
  font-size: 15px;
}

.box-3-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 850px;
  margin-top: 30px;
  max-width: 850px;
}

.box-3-scroll img {
  width: 589px;
  height: 411px;
  margin-bottom: 110px;
}

.box-3-scroll img:last-child {
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.title-2 {
  width: 1555px;
  text-align: center;
  margin: 220px auto 0;
}

.title-2 > div {
  height: 8px;
  width: 64px;
  background-color: #75b9f6;
  margin: 0 auto;
}

.title2-p1 {
  font-size: 31px;
  color: #222;
  margin: 60px 0 50px;
  font-weight: bold;
}

.title2-p2 {
  font-size: 16px;
  color: #666;
}

.box-4 {
  width: 1555px;
  padding: 0 250px 150px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.box4-p {
  text-align: center;
  color: #666;
  font-size: 14px;
}

.box4-item {
  height: 140px;
  width: 140px;
  flex-direction: column;
  margin-top: 90px;
}

.box4-item > img {
  width: 63px;
  height: 63px;
}

.box-4 > img {
  width: 59px;
  height: 33px;
}
.ints {
  position: fixed;
  right: 100px;
  bottom: 370px;
}
.ints img {
  width: 50px;
  height: 50px;
}
</style>

<template>
  <div class="home">
    <div class="carousel-bg">
      <el-carousel indicator-position="outside">
        <el-carousel-item
          v-for="(item, index) in banner"
          autoplay
          interval="5000"
          :key="index"
        >
          <img :src="item.image" />
        </el-carousel-item>
      </el-carousel>
      <TopTab></TopTab>
      <div class="toBotton-icon" @click="toBottom">
        <img src="../assets/images/toBottom.png" />
      </div>
    </div>
    <div ref="bottom" class="title-1 flexCenter">
      <p>了解我们的产品服务</p>
      <p>
        佑护科技将为您提供：专业的行业解决方案、前沿的移动技术开发、高曝光易传播的运营策划、如沐春风的客服服务。
      </p>
    </div>
    <div class="box-1 flex-between">
      <div
        :class="[
          'box-1-item flex-y-center',
          { 'box-1-active': navIndex2 == index },
        ]"
        v-for="(item, index) in navList2"
        :key="index"
        @click="seletNav2(index)"
      >
        <div
          :class="[
            'box-1-item-icon flexCenter',
            { 'box-1-item-icon-active': navIndex2 == index },
          ]"
        >
          <img :src="navIndex2 == index ? box_1_icon2 : box_1_icon1" />
        </div>
        <p>{{ item }}</p>
      </div>
    </div>
    <div class="box-2 flex-around">
      <div
        class="box-2-item flex-x-center"
        v-for="(item, index) in navList3[navIndex2]"
        :key="index"
      >
        <p class="box-2-p1">{{ item.title }}</p>
        <p class="box-2-p2">{{ item.content }}</p>
      </div>
    </div>
    <div class="box-3 flex">
      <div class="box-3-left">
        <div class="box-3-inner">
          <div class="box3-inner1"></div>
          <div class="box3-inner2">
            <p>互联网营销</p>
            <p>解决方案供应商</p>
          </div>
          <div class="box3-inner3">
            <div class="box3-y">
              <div></div>
            </div>
            <p>小程序服务解决方案</p>
          </div>
        </div>
        <div class="list-boxs flex">
          <div class="list-boxs-unp" v-for="(item, index) in 48" :key="index">
            <div class="unp-inner"></div>
          </div>
        </div>
        <div class="box-unp1"></div>
        <div class="box-unp2"></div>
        <div class="box-unp3"></div>
      </div>
      <div class="box-3-right">
        <div></div>
        <p class="box-3-p1">小程序服务解决方案</p>
        <p class="box-3-p2">
          小程序是微信内部能够被快捷获取和传播的轻应用，为国内大中小企业追捧，已有近2亿活跃用户使用小程序
          佑护科技围绕微信小程序特性打造企业专属一站式解决方案。
        </p>
        <div class="box-3-scroll" v-if="info.index_image">
          <img
            v-for="item in info.index_image"
            :key="item"
            :src="item"
            alt=""
          />
        </div>
        <div class="box-3-scroll" v-else>
          <img src="../assets/images/index-box3-bg2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="title-2">
      <div></div>
      <p class="title2-p1">服务开发流程</p>
      <p class="title2-p2">一对一设计服务，优质技术保障</p>
    </div>
    <div class="box-4 flex-x-center flex-around">
      <div class="flex" v-for="(item, index) in navList4" :key="index">
        <div class="box4-item flex-between flexCenter">
          <img :src="item.icon" alt="" />
          <div class="box4-p">
            <p>{{ item.text }}</p>
            <p>{{ item.text_2 }}</p>
          </div>
        </div>
        <div
          v-if="index != 2 && index != 5"
          class="flex-y-center"
          style="margin: 90px 125px 0"
        >
          <img src="../assets/images/index-box4-icon7.png" />
        </div>
      </div>
    </div>
    <Bottom :minWidth="minWidth"></Bottom>
  </div>
</template>
<script>
import TopTab from "../../components/topTab.vue";
import Bottom from "../../components/bottom.vue";
export default {
  name: "home",
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem("topBottomInfo")),
      minWidth: 1555,
      banner: [],
      navList2: ["建站服务", "微信公众号运营", "APP定制开发", "小程序开发"],
      navIndex2: 0,
      navList3: [
        [
          {
            title: "解决方案",
            content:
              "提供手机网站、PC网站、微网站解决方案，针对不同类型的网站需求定制开发",
          },
          {
            title: "个性化",
            content:
              "对企业、客户、市场进行专业的分析，运用独特的创意设计手段以及现金的网站开饭技术，为企业设计出独一无二的定制网站",
          },
          {
            title: "优势",
            content:
              "网站功能齐全，能极大满足客户需求三站合一、PC、手机、wab三合一、安全可靠，性价比高、速度快、稳定性好",
          },
        ],
        [
          {
            title: "公众号成长计划",
            content:
              "打造微信公众号成长计划，包括精确定位用户需求、创造关系、获取流量、植入产品、产生交易。",
          },
          {
            title: "完善的营销体系",
            content:
              "集品牌展示、粉丝吸引计划、品牌主题策划、客服专员支持、图文采集、消息推送为一体，打造内容营销+品牌雕塑+公众号传播+系统营销体系。",
          },
        ],
        [
          {
            title: "解决方案",
            content:
              "提供android、ios两大主流平台应用解决方案，产品设计前卫、先进，根据企业的实际情况与需求出发，以独到的设计理念和精工细作的专业精神，定制出专业的行业解决方案。",
          },
          {
            title: "适配性强",
            content:
              "能够根据客户的服务需求超过300款真机测试，适配主流机型，速发各大应用市场",
          },
        ],
        [
          {
            title: "解决方案",
            content:
              "提供专业的微信应用开发解决方案，包括微信应用开发、小程序开发，后台涵盖了几十个行业。",
          },
          {
            title: "多样化",
            content:
              "上百种应用，打造多样化的公众号营销，而小程序能让企业实现应用“触手可及”的梦想，利用微信流量大口，助力企业快速步入互联网时代。",
          },
        ],
      ],
      navList4: [
        {
          icon: require("../assets/images/index-box4-icon1.png"),
          text: "顾问咨询",
          text_2: "需求调研分析",
        },
        {
          icon: require("../assets/images/index-box4-icon2.png"),
          text: "全案设计",
          text_2: "初期合理设计规划",
        },
        {
          icon: require("../assets/images/index-box4-icon3.png"),
          text: "设计验收",
          text_2: "客户进行验收",
        },
        {
          icon: require("../assets/images/index-box4-icon4.png"),
          text: "开发制作",
          text_2: "技术开发实现",
        },
        {
          icon: require("../assets/images/index-box4-icon5.png"),
          text: "产品测试",
          text_2: "稳定而又周密测试",
        },
        {
          icon: require("../assets/images/index-box4-icon6.png"),
          text: "网站上线",
          text_2: "开展网络营销",
        },
      ],
      box_1_icon1: require("../assets/images/computer.png"),
      box_1_icon2: require("../assets/images/computer-2.png"),
      activeName: "first",
    };
  },
  components: {
    TopTab,
    Bottom,
  },
  mounted() {
    window.addEventListener("scroll", this.btn_pos);
  },
  created() {
    var topBottomInfo = sessionStorage.getItem("topBottomInfo");
    if (!topBottomInfo) {
      this.http({
        method: "get",
        url: this.api.get_site_info,
      }).then((res) => {
        this.info = res.data;
        sessionStorage.setItem("topBottomInfo", JSON.stringify(res.data));
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute(
            "content",
            this.info.web_keywords
              ? this.info.web_keywords
              : "佑护科技 互利网营销,企业信息化建设,网络开发,APP开发,新冠肺炎,新增病例,特朗普"
          );
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            this.info.web_description
              ? this.info.web_description
              : "佑护科技有限公司，是一家集企业信息化建设、网络开发、互利网营销于一体的综合性IT公司"
          );
      });
    }
    this.getBanner();
  },
  methods: {
    getBanner() {
      this.http({
        method: "get",
        url: this.api.getBanner,
        data: {},
      }).then((res) => {
        this.banner = res.data;
      });
    },
    toBottom() {
      this.$refs.bottom.scrollIntoView({
        behavior: "smooth",
      });
    },
    seletNav2(index) {
      this.navIndex2 = index;
    },
  },
};
</script>
